// Generated by Framer (5eef525)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import DarkMode from "https://framerusercontent.com/modules/lBVrplEvhPQdM9lKsOuU/C5IYBgWIua0a4EcSoTLN/DarkMode.js";
const DarkModeFonts = getFonts(DarkMode);

const cycleOrder = ["HRKajjjAx", "CI_0N8h2p"];

const serializationHash = "framer-DA6v9"

const variantClassNames = {CI_0N8h2p: "framer-v-1dkx0nk", HRKajjjAx: "framer-v-1jlqn80"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Bottom light": "HRKajjjAx", Simple: "CI_0N8h2p"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "HRKajjjAx"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "HRKajjjAx", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1jlqn80", className, classNames)} data-framer-name={"Bottom light"} layoutDependency={layoutDependency} layoutId={"HRKajjjAx"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-b576ebcc-cc84-43bc-9c7f-1f4051d24710, rgba(255, 255, 255, 0))", borderBottomLeftRadius: 99, borderBottomRightRadius: 99, borderTopLeftRadius: 99, borderTopRightRadius: 99, boxShadow: "0px -1px 0px 0px var(--token-53e2bfe5-ec96-40ef-a120-73950b73aee7, rgb(224, 226, 230)), 0px 1px 0px 0px var(--token-d13561b1-2db0-4599-a73a-d45fdc438ab3, rgb(224, 226, 230))", ...style}} variants={{CI_0N8h2p: {boxShadow: "none"}}} {...addPropertyOverrides({CI_0N8h2p: {"data-framer-name": "Simple"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-3zlgj9-container"} layoutDependency={layoutDependency} layoutId={"hCO9QHm5h-container"}><DarkMode darkHover={"var(--token-b576ebcc-cc84-43bc-9c7f-1f4051d24710, rgba(255, 255, 255, 0))"} darkIcon={"var(--token-464c598b-543f-4ed5-b0b0-d7f1c4a938d9, rgb(36, 36, 42))"} height={"100%"} id={"hCO9QHm5h"} layoutId={"hCO9QHm5h"} lightHover={"var(--token-b576ebcc-cc84-43bc-9c7f-1f4051d24710, rgba(255, 255, 255, 0))"} lightIcon={"var(--token-464c598b-543f-4ed5-b0b0-d7f1c4a938d9, rgb(36, 36, 42))"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DA6v9.framer-1kbvig3, .framer-DA6v9 .framer-1kbvig3 { display: block; }", ".framer-DA6v9.framer-1jlqn80 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-DA6v9 .framer-3zlgj9-container { flex: none; height: auto; position: relative; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DA6v9.framer-1jlqn80 { gap: 0px; } .framer-DA6v9.framer-1jlqn80 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-DA6v9.framer-1jlqn80 > :first-child { margin-left: 0px; } .framer-DA6v9.framer-1jlqn80 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"CI_0N8h2p":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZQcpcgBk1: React.ComponentType<Props> = withCSS(Component, css, "framer-DA6v9") as typeof Component;
export default FramerZQcpcgBk1;

FramerZQcpcgBk1.displayName = "Elements/Dark mode Toggle";

FramerZQcpcgBk1.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerZQcpcgBk1, {variant: {options: ["HRKajjjAx", "CI_0N8h2p"], optionTitles: ["Bottom light", "Simple"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerZQcpcgBk1, [{explicitInter: true, fonts: []}, ...DarkModeFonts], {supportsExplicitInterCodegen: true})